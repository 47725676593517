import React from "react";

const Description = () => (
  <section>
    <h2>UPDATE: The Nitter project has been discontinued</h2>
    <p>
      The Nitter project has been discontinued due to Twitter API changes which
      make it impossible to continue development. See the{" "}
      <a
        href="https://github.com/zedeus/nitter/issues/1171"
        target="_blank"
        rel="noreferrer"
      >
        Thank you (1171)
      </a>{" "}
      issue for more information.
    </p>
    <p>
      It may be possible for some to find a workaround, such as using personal
      twitter accounts, but so far a scalable solution has not been found.
      Thanks to zedeus and all the contributors for their hard work on the
      project to keep privacy alive.
    </p>
    <h2>=====================</h2>
    <p>
      Twit2Nit works by checking intances listed on the{" "}
      <a href="https://github.com/zedeus/nitter/wiki/Instances">
        github nitter instances
      </a>{" "}
      every five minutes to estimate their availability.
    </p>
    <p>
      Enter a Twitter Name or URL and press "LIST" to view an available instance
      list pre-populated with your name or "GO RANDOM!" to be automatically
      redirected to a random instance.
    </p>
    <p>
      Random is recommended to more evenly distribute the load across all
      available instances, though results are less reliable than selecting your
      favorite intance from the list, as some unstable instances sometimes
      experience uptime volatility in short periods of time.
    </p>
  </section>
);

export default Description;
